.home-page {
    background-color: #e8e7e7;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .menu-card {
        border: solid 1px transparent;
        border-radius: 10px;
        background-color: white;
        margin: 10px;
        box-shadow: 1px 1px 8px 2px #888888;
        display: flex;

        .menu-card__img {
            width: 25%;
            position: relative;
        }

        .menu-card__content {
            width: 70%;
            position: relative;
        }

        .menu-card__caret {
            width: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    .trust-container{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        padding:5px 5px; 
        align-items: center;
        .trust-item{
            img{
                width: 40px;
                height: 40px;
            }
        }
        .trust-item2{
            img{
                width: 80pt;               
            }
        }
    }

    .guide-button {
        border: solid 1px white;
        border-radius: 10px;
        color: white;
        display: flex;
        justify-content: center;
    }

    .home-page__footer {
        background-color: white;
        -webkit-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.21);
        -moz-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.21);
        box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.21);
        z-index: 100;
    }
    .statistic{
        background-color:#f4f4f4;
        padding:10px;
        border-radius:5px;
        font-size: 17px;
        text-align: left;
        .statistic_top{
            border-bottom: solid 1px;
            text-align: left;
            padding-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 5px;
            .logo_product{
                width: 50px;               
                margin-right: 10px;
                display: inline;
            }
        }
    }
}
.slide{
    background-color: rgba(52, 52, 52, 0) !important
}
.w90{
    width:90px
}
.w80{
    width:80px
}
.livenow {
    width: 24px;
   // padding: 10px 40px 12px 40px;
    margin: 0 auto;
   height: 24px;

}
.livenow > div {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    margin: 0 auto;
    border:3px solid #169714;
    -webkit-animation: live 1.4s infinite ease-in-out;
    animation: live 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &:nth-child(1) {
        background-color:rgba(255,255,255,0.3);
        background-color:#169714;
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }
      &:nth-child(2) {
        -webkit-animation-delay: 0.16s;
        animation-delay: 0.16s;
      }
      &:nth-child(3) {
        -webkit-animation-delay: 0.42s;
        animation-delay: 0.42s;
        border:3px solid rgba(255,255,255,0.87);
      }
      &:nth-child(4) {
        border:3px solid #169714;
        -webkit-animation-delay: -0.42s;
        animation-delay: -0.42s;
      }
}
@-webkit-keyframes live {
    0%, 80%, 100% { -webkit-transform: scale(0.6) }
    40% { -webkit-transform: scale(1.0) }
  }
  @keyframes live {
    0%, 80%, 100% { 
      transform: scale(0.6);
      -webkit-transform: scale(0.6);
    } 40% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }