.navbar {
  position: sticky;
  top: 0;

  right: 0;
  left: 0;

  z-index: 1000;
  background-color: #169714;
  border-bottom: $navbar-border-bottom;
  box-shadow: $navbar-box-shadow;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  padding-top: 5px;
  padding-bottom: 5px;
  //background: rgb(53,64,82);
  //background: linear-gradient(106deg, rgba(53,64,82,1) 0%, rgba(77,82,53,0.9808298319327731) 50%, rgba(53,64,82,1) 100%);

}

.navbar-brand {
  font-weight: $navbar-brand-font-weight;
  font-size: $navbar-brand-font-size;
  padding: $navbar-brand-padding-y $navbar-brand-padding-x;
  color: $navbar-brand-color;
  display: block;

  svg,
  .feather {
    color: $sidebar-brand-icon-color;
    height: 24px;
    width: 24px;
    margin-left: -0.15rem;
    margin-right: 0.75rem;
    margin-top: -0.375rem;
  }
}

.nav-icon,
.nav-flag {
  padding: .1rem .8rem;
  display: block;
  font-size: 1.5rem;
  color: $gray-600;
  transition: $transition-appearance-fast;
  line-height: 1.4;

  &:after {
    display: none !important;
  }

  &:hover,
  &.active {
    color: $primary;
  }

  svg,
  .feather {
    width: 25px;
    height: 25px;
  }
}

.nav-item {
  .indicator {
    background: yellowgreen;
    box-shadow: $box-shadow;
    border-radius: 50%;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 18px;
    padding: 1px;
    position: absolute;
    top: 0;
    right: -8px;
    text-align: center;
    transition: top .1s ease-out;
    font-size: 0.775rem;
    color: $white;
  }

  &:hover .indicator {
    top: -4px;
  }

  a:focus {
    outline: 0;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar .avatar {
    max-height: 47px;
  }
}

@include media-breakpoint-down(xs) {
  .navbar {
    padding: 0.75rem;
  }

  .nav-icon {
    padding: .1rem .75rem;
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: inherit;
  }

  .navbar-expand .navbar-nav .dropdown-menu-lg {
    min-width: 100%;
  }

  .nav-item .nav-link:after {
    display: none;
  }
}
